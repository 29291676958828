
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Save extends Vue {
  protected _boundListener: any = "";

  created() {
    this._boundListener = this._keyListener.bind(this);
    document.addEventListener("keydown", this._boundListener);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this._boundListener);
  }

  protected _keyListener(e: any) {
    this.$emit("showWarning", true);
    if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
      e.preventDefault(); // present "Save Page" from getting triggered.
      this.$emit("showWarning", false);
      this.$emit("saveKeypress");
    }
  }
}
