import { GlobalState } from "./types";

export const setLoading = (state: GlobalState, value: boolean) => {
  state.isLoading = value;
};

export const setLoggedIn = (state: GlobalState, value: boolean) => {
  state.isLoggedIn = value;
};

export const setBusinessUnit = (state: GlobalState, value: string) => {
  state.bu = value;
};