import { GlobalState } from "./types";

export const getLoading = (state: GlobalState): boolean => {
  return state.isLoading;
};

export const getLoggedIn = (state: GlobalState): boolean => {
  return state.isLoggedIn;
};

export const getBusinessUnit = (state: GlobalState): string | null => {
  return state.bu;
};
