import VueRouter, { RouteConfig } from "vue-router";

import Vue from "vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "NotFound",
    component: () => import("../components/NotFound.vue")
  },
  {
    path: "",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: "Category1",
        component: () => import("../components/category/Category.vue")
      },
      {
        path: "/category",
        name: "Category2",
        component: () => import("../components/category/Category.vue")
      },
      {
        path: "/category/list/:path+",
        name: "CategoryList",
        component: () => import("../components/category/Category.vue")
      },
      {
        path: "/category/edit/:id",
        name: "CategoryEditor",
        component: () => import("../components/category/CategoryEditor.vue")
      },
      {
        path: "/category/new",
        name: "CategoryCreator",
        component: () => import("../components/category/CategoryEditor.vue")
      },
      {
        path: "/manufacturer",
        name: "Mfr",
        component: () => import("../components/manufacturer/Mfr.vue")
      },
      {
        path: "/manufacturer/edit/:id",
        name: "MfrEditor",
        component: () => import("../components/manufacturer/MfrEditor.vue")
      },
      {
        path: "/manufacturer/new",
        name: "MfrCreator",
        component: () => import("../components/manufacturer/MfrEditor.vue")
      },
      {
        path: "/product",
        name: "Product",
        component: () => import("../components/product/Product.vue")
      },
      {
        path: "/product/edit/:id",
        name: "ProductEditor",
        component: () => import("../components/product/ProductEditor.vue")
      },
      {
        path: "/product/new",
        name: "ProductCreator",
        component: () => import("../components/product/ProductEditor.vue")
      },
      {
        path: "/part",
        name: "Part",
        component: () => import("../components/part/Part.vue")
      },
      {
        path: "/part/new",
        name: "PartCreator",
        component: () => import("../components/part/PartEditor.vue")
      },
      {
        path: "/part/edit/:id",
        name: "PartEditor",
        component: () => import("../components/part/PartEditor.vue")
      },
      {
        path: "/attribute",
        name: "Attribute",
        component: () => import("../components/attribute/Attribute.vue")
      },
      {
        path: "/attribute/edit/:id",
        name: "AttributeEditor",
        component: () => import("../components/attribute/AttributeEditor.vue")
      },
      {
        path: "/attribute/new",
        name: "AttributeCreator",
        component: () => import("../components/attribute/AttributeEditor.vue")
      },
      {
        path: "/asset",
        name: "Asset",
        component: () => import("../components/asset/Asset.vue")
      },
      {
        path: "/asset/assets/:path+",
        name: "AssetList",
        component: () => import("../components/asset/Asset.vue")
      },
      {
        path: "/asset/edit/:id",
        name: "AssetEditor",
        component: () => import("../components/asset/AssetEditor.vue")
      },
      {
        path: "/asset/new",
        name: "AssetCreator",
        component: () => import("../components/asset/AssetEditor.vue")
      },
      {
        path: "/utility",
        name: "Utility",
        component: () => import("../components/utility/Utility.vue")
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
