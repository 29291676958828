import { GlobalContext, GlobalMutations } from "./types";

export const addLoading = (context: GlobalContext, value: boolean) => {
  context.commit(GlobalMutations.SetLoading, value);
};

export const addLoggedIn = (context: GlobalContext, value: boolean) => {
  context.commit(GlobalMutations.SetLoggedIn, value);
};

export const addBusinessUnit = (context: GlobalContext, value: string) => {
  context.commit(GlobalMutations.SetBusinessUnit, value);
};