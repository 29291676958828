export type GlobalState = {
  isLoading: boolean;
  isLoggedIn: boolean;
  bu: string | null;
};

export type GlobalContext = {
  state: GlobalState;
  dispatch: (action: GlobalActions, payload?: any) => any;
  commit: (mutation: GlobalMutations, payload: any) => any;
};

export enum GlobalMutations {
  SetLoading = "setLoading",
  SetLoggedIn = "setLoggedIn",
  SetBusinessUnit = "setBusinessUnit",
}

export enum GlobalActions {
  AddLoading = "addLoading",
  AddLoggedIn = "addLoggedIn",
  AddBusinessUnit = 'addBusinessUnit'
}

export enum GlobalGetters {
  GetLoading = "getLoading",
  GetLoggedIn = "getLoggedIn",
  GetBusinessUnit = 'getBusinessUnit'
}
