
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import CategoryService from "@/services/category_service";
import { CategoryModel } from "@/models/category";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events";
import { makeSentenceCase } from "@/utility/helpers";
import { AssetModel } from "@/models/asset";

@Component({
  components: {
    Icon
  }
})
export default class ConfirmMove extends Vue {
  @Prop() data!: any;
  @Prop() moveDestination!: AssetModel;
  @Prop() oldDestination!: string;
  protected makeSentenceCase = makeSentenceCase;

  protected confirm(): void {
    if (
      this.oldDestination !==
      this.moveDestination.path + this.moveDestination.filename
    ) {
      this.data.forEach((item: AssetModel, index: number) => {
        if (index === this.data.length - 1) {
          this.$emit(
            "sendMoveRequest",
            this.moveDestination.path + this.moveDestination.filename,
            item.id,
            item.display_name ? item.display_name : item.filename,
            item,
            true
          );
        } else {
          this.$emit(
            "sendMoveRequest",
            this.moveDestination.path + this.moveDestination.filename,
            item.id,
            item.display_name ? item.display_name : item.filename,
            item,
            false
          );
        }
      });
    } else {
      EventBus.$emit("showError", "Move request failed.");
    }
  }
}
