import {
  APIError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import { MfrModel, MfrRequestOptions } from "@/models/mfr";
import axios, { AxiosResponse } from "axios";

import { APIResponse } from "@/models/api_res";
import Vue from "vue";

/**
 * Creates a new Mfr Service

 * @example
 * const mfrService = new MfrService();
 * mfrService.getmfr();
 */

export default class MfrService {
  /**
   * Get paginated list of mfrs
   * Filtered by parameters
   * @param {object} optionsObject optional:
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10)
   *    @param {string} bu The business unit to filter by, optional (default = 'bmh')
   *    @param {string} q Query term for keyword search
   *    @param {boolean} empty Filters the resulting categories to only include empty categories.
   *    (currently bu is never used, so it is not included in logic)
   * }
   *
   *
   * @returns {APIResponse} - response object
   */
  public async getMfrs(
    optionsObject?: MfrRequestOptions
  ): Promise<APIResponse> {
    let mfrs;
    let url = process.env.VUE_APP_API_URL_NODE + "/manufacturers";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.page) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "page=" + optionsObject.page;
      }
      if (optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "q=" + optionsObject.q;
      }
      if (optionsObject.offset) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "per_page=" + optionsObject.offset;
      }
      if (optionsObject.empty) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "empty";
      }

      if (optionsObject.bu) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "bu=" + optionsObject.bu;
      }
    }
    try {
      const res = await axios.get(url);
      mfrs = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return mfrs;
  }

  /**
   * POST new mfr to server
   * @param {Mfr} request - new mfr data object
   * @returns {Mfr} - newly created mfr object
   */
  public async createNewMfr(request: MfrModel): Promise<MfrModel> {
    let mfr;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/manufacturers",
        body,
        options
      );
      mfr = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Manufacturer already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return mfr;
  }

  /**
   * Get selected single mfr
   * @param {number} id - mfr id
   * @returns {Mfr} - selected mfr's data object
   */
  public async getSingleMfr(id: number): Promise<MfrModel> {
    let mfr: MfrModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        process.env.VUE_APP_API_URL + "/manufacturers/" + id
      );
      mfr = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested manufacturer not found.");
      } else {
        throw new APIError(
          "Something went wrong. Please try again.",
          err.status
        );
      }
    }
    return mfr;
  }

  /**
   * Save current mfr
   * @param {number} id - mfr id
   * @param {Mfr} request - mfr request data
   * @returns {Promise<void>}
   */
  public async saveMfr(request: MfrModel, id: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL + "/manufacturers/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested manufacturer not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Manufacturer already exists.");
      } else if (err.response && err.response.status === 422) {
        throw new EntityError("Error: Action is not allowed.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete mfr
   * @param {number} id - mfr id
   * @returns {Promise<void>}
   * A mfr can only be deleted if it is a leaf node and there are no products attached to it. If either of these conditions are broken, a 422 will be returned.
   */
  public async deleteMfr(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(
        process.env.VUE_APP_API_URL + "/manufacturers/" + id
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested manufacturer not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Manufacturer already exists.");
      } else if (err.response && err.response.status === 422) {
        throw new EntityError("Error: Action is not allowed.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
}
