/**
 *
 * To reorder items, a delta value must be included in patch request body.
 * The delta represents the change in index position.
 *
 * INDEXES FOR ORDERING PURPOSES START AT 1. THIS METHOD ASSUMES THAT THIS
 * IS TAKEN INTO ACCOUNT BEFORE NEWINDEX VALUE IS PASSED IN.
 *
 * @example ...
 * newIndex = 1
 * oldIndex = 3
 * delta = -2
 *
 * newIndex = 20
 * oldIndex = 10
 * delta = 10
 *
 * @param {string} oldIndex starting index
 * @param {string} newIndex new index
 * @returns {number} delta change in index
 */

import { AssetModel } from "@/models/asset";

export function getDelta(oldIndex: number, newIndex: number): number {
  return newIndex - oldIndex;
}

/** Make string sentence case
 * @param {string} option string to be transformed
 */
export function makeSentenceCase(option: string) {
  return option.charAt(0).toUpperCase() + option.slice(1);
}

export function convertToSlug(str: string): string {
  return str ? str.replace(/\s+/g, "-").toLowerCase() as string : '';
}

export function filenameRegex(name: string): string {
  return name.replace(/[^\w\-_.]/g, "");
}

/**
 * Returns full string image path given AssetModel object & width for resizing
 * @param image {AssetModel} object
 * @param width {number} image width
 * @returns {string} image url
 */
export function getImage(image: AssetModel, width: number | string): string {
  const cdn = process.env.VUE_APP_CDN_URL;
  const path = image.path || image.image_path;
  const filename = image.filename || image.image_filename;

  if(path) {
    return (cdn +
      path +
      convertToSlug(filename as string) +
      "?width=" +
      width) as string;
  }
  
  return '';
}

export function getLink(image: AssetModel): string {
  const cdn = process.env.VUE_APP_CDN_URL;
  return (cdn + image.path + image.filename) as string;
}

export function fileTypeDisplay(type: string): string {
  let name = "Folder";
  switch (type) {
    case "doc":
      name = "Document";
      break;
    case "img":
      name = "Image";
  }
  return name;
}

/**
 *
 * @param path path to be formatted
 * @returns formatted string for breadrumb
 *
 * This method is only called on paths one level down from root (ex: path will never be '/'),
 * so it should never be null
 *
 */
export function formatAssetCrumb(path: string): string | null {
  let string = null;
  if (path !== "/") {
    const pieces = path.split("/");
    const len = pieces.length;
    const item = decodeURI(pieces[len - 1].replace("/", ""));
    const _string = item.replace(/-/g, " ");
    string = _string.replace(/_/g, " ");
  }
  return string;
}


export const toQueryString = (obj:any) => "?".concat(Object.keys(obj).map(e => obj[e] ? `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}` : null).filter(e => !!e).join("&"));