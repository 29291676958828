import {
  APIError,
  AttributeConflictError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import { AttributeModel, AttributeRequestOptions } from "@/models/attribute";

import { APIResponse } from "@/models/api_res";
import Vue from "vue";
import axios from "axios";

/**
 * Creates a new Attribute Service

 * @example
 * const attributeService = new AttributeService();
 * attributeService.getattribute();
 */

export default class AttributeService {
  /**
   * Get paginated list of attribute
   * Filtered by parameters
   * @param {object} optionsObject optional:
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10 in the api, setting to 50 here)
   *    @param {string} filter filter the attributes list by attribute values
   *    @param {string} q Query term for keyword search
   *    @param {boolean} empty Filters the resulting categories to only include empty categories.
   *        @example ...
   *          filter[length]=1&filter[length]=2&filter[width]=3
   *
   *
   *
   * @returns {APIResponse} - response object
   */
  public async getAttributes(
    optionsObject?: AttributeRequestOptions
  ): Promise<APIResponse> {
    let attributes;
    let url = process.env.VUE_APP_API_URL + "/attributes";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.page) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "page=" + optionsObject.page;
      }
      if (optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "q=" + optionsObject.q;
      }
      if (optionsObject.offset) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "per_page=" + optionsObject.offset;
      }
      if (optionsObject.empty) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "empty";
      }

      if (optionsObject.bu) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "bu=" + optionsObject.bu;
      }
    }
    try {
      const options = {
        withCredentials: true
      };
      const res = await axios.get(url);
      attributes = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return attributes;
  }

  /**
   * POST new attribute to server
   * @param {Attribute} request - new attribute data object
   * @returns {Attribute} - newly created attribute object
   */
  public async createNewAttribute(
    request: AttributeModel
  ): Promise<AttributeModel> {
    let attribute;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/attributes",
        body,
        options
      );
      attribute = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 409) {
        throw new AttributeConflictError(
          "Error: Attribute already exists.",
          err.response.data.details
        );
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return attribute;
  }

  /**
   * Get selected single attribute
   * @param {number} id - attribute id
   * @returns {Attribute} - selected attribute's data object
   */
  public async getSingleAttribute(id: number): Promise<AttributeModel> {
    let attribute: AttributeModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        process.env.VUE_APP_API_URL + "/attributes/" + id
      );
      attribute = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested attribute not found.");
      } else {
        throw new APIError(
          "Something went wrong. Please try again.",
          err.status
        );
      }
    }
    return attribute;
  }

  /**
   * Save current attribute
   * @param {number} id - attribute id
   * @param {Attribute} request - attribute request data
   * @returns {Promise<void>}
   */
  public async saveAttribute(
    request: AttributeModel,
    id: number
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL + "/attributes/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested attribute not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Attribute already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete attribute
   * @param {number} id - attribute id
   * @returns {Promise<void>}
   */
  public async deleteAttribute(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(
        process.env.VUE_APP_API_URL + "/attributes/" + id
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested attribute not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
}
